import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import headerStyles from './header.module.scss'
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false
    }
  }

  render() {
    
    return (
      <header>
        <nav className={`navbar is-fixed-top is-white ${headerStyles.navbar}`} role="navigation" aria-label="main navigation">
         <div className="container">
         <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img src="/images/Logo_3.png" height="3.5rem" alt="US Safety Equipment.com Logo" style={{marginBottom: 0, maxHeight: '3rem'}}/>
            </Link>
  
            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
            <a role="button" className={`navbar-burger burger ${this.state.navActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={() => {
              this.setState({navActive: !this.state.navActive});
            }}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
  
          <div id="navbar" className={`navbar-menu ${this.state.navActive ? 'is-active' : ''}`}>
            <div className="navbar-start">
              <Link className="navbar-item" activeClassName={headerStyles.activeNavItem} to="/">Home</Link>            
  
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link" href="#">
                  Products
                </a>
  
                <div className="navbar-dropdown">
                  <Link to="/body-harnesses/" className="navbar-item">
                    Body Harnesses
                  </Link>
                  <Link to="/lanyards-and-lifelines/" className="navbar-item">
                    Lanyards &amp; Lifelines
                  </Link>
                  <Link to="/anchors-and-positioning/" className="navbar-item">
                    Anchors &amp; Positioning
                  </Link>
                  <hr className="navbar-divider" />
                  <Link className="navbar-item" to="/contact">
                    Request A Quote
                  </Link>
                </div>
              </div>
              
              <Link className="navbar-item" to="/about">About</Link>
            </div>

            <div className={`${headerStyles.navbarEnd} navbar-end`}>
              <span className={`navbar-item is-hidden-touch ${headerStyles.phone}`}>1-800-331-2973</span>

              <a className="navbar-item" title="Shopping Cart" href={this.props.data.site.siteMetadata.cartUrl}>
                
                <FontAwesomeIcon  size="md" icon="shopping-cart" />
              </a>

            </div>
          </div>
         </div>
        </nav>
      </header>
        
    )
  }
}

export default (props) => (
  <StaticQuery
    query={
      graphql`
      query {
        site {
          siteMetadata {
            cartUrl
          }
        }
      }`
    }
    render = { data => (
     <Header data={data}/>
    )}
  />
);
