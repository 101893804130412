import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>Copyright&copy; US Safety Equipment / National Tool Grinding, Inc 2019</p>
        <Link to="/policies">Privacy &amp; Return Policies</Link>
      </div>
    </footer>
    
  )
}

export default Footer