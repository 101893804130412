import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart, faBookOpen  } from '@fortawesome/free-solid-svg-icons'
import Header from './header';
import Footer from './footer';
import '../styles/bulma.css';
import '../styles/index.scss';
import layoutStyles from './layout.module.scss'
import { Helmet } from 'react-helmet';

library.add(faShoppingCart, faBookOpen);

const Layout = (props) => {
  return (
    <div>
      <div id="root" className={layoutStyles.root}>
        <Helmet>
          { props.metaDesc && 
            <meta name="description" content={props.metaDesc} />
          }

          { props.title &&
            <title>{props.title}</title>
          }
          <script data-cfasync="false" src="https://cdn.foxycart.com/secure.ussafetyequipment.com/loader.js" async defer></script>
          {/* <link href="https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap" rel="stylesheet" />  */}
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest"></link>
          
        </Helmet>
        <Header />

        <div className={`container ${layoutStyles.content}`}>
          {props.children}
        </div>
        
        <Footer />
      </div>
    </div>
    
  )
}

export default Layout;